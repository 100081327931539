history.scrollRestoration = 'manual';
import { Loader } from './loader';
import { Cursor } from './cursor';
import { gsap,Expo,Power2 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { tns } from 'tiny-slider/src/tiny-slider';
import imageFrames from "../images/frames/*.png";
import imageFramesBack from "../images/frames-back/*.png";
import virusFrames from "../images/virus/*.png";

new Loader();
new Cursor();

gsap.registerPlugin(ScrollTrigger,Expo,Power2);

const isTouch = function() { return navigator.userAgent.match(/iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i); };
const isIphone = function() { return navigator.userAgent.match(/iPhone/i); };
const isXXsWidth = window.innerWidth < 480;

const canvas = document.querySelector("#canvas");
const context = canvas.getContext("2d");
let wrapper = document.querySelector('.canvas-wrapper');
let cursorWrapper = document.querySelector(".global-circle");

const firstSection = document.querySelector('.one');
const fiveSection = document.querySelector('.five');

canvas.width = 1920;
canvas.height = 1080;

const frameCountAll = 65;

const frameCountBack = 26;
const virusFramesCount = 134;

const images = [];
const imagesBack = [];
const viruses = [];
const frames = {
    frame: 1
};

window.onload = () => {
    clearCanvas(context);
    context.drawImage(images[1], 0, 0);
};

for (let i = 0; i < frameCountAll; i++) {
    const img = new Image();
    img.src = imageFrames[i + 1];
    images.push(img);
}

for (let i = 0; i < frameCountBack; i++) {
    const img = new Image();
    img.src = imageFramesBack[i];
    imagesBack.push(img);
}

for (let i = 0; i < virusFramesCount; i++) {
    const img = new Image();
    img.src = virusFrames[i];
    viruses.push(img);
}

let tl = new gsap.timeline({
    scrollTrigger: {
        trigger: firstSection,
        start: "top top",
        end: "50%",
        scrub: true,
        pin:true,
    },
});

if (isXXsWidth) {
    tl.to(canvas, {scale: 1,duration: 1});
} else {
    tl.to(canvas, {scale: 1,duration: 1});
}


let startViruses = '200%';

gsap.to(frames, {
    frame: frameCountAll - 1,
    snap: "frame",
    scrollTrigger: {
        trigger: firstSection,
        start: "top top",
        end: startViruses,
        scrub: true,
        onUpdate: (self) => {
            renderOne();
        },
    },
});

document.querySelectorAll('.scene__text').forEach((element) => {
    gsap.to(element, {
        opacity:1,
        scrollTrigger: {
            trigger: element.parentNode,
            start: "top top",
            end: "100%",
            scrub: true,
            pin: true,
        },
    });
});

gsap.to(frames, {
    frame: virusFramesCount - 1,
    snap: "frame",
    opacity:1,
    scrollTrigger: {
        trigger: firstSection,
        start: startViruses,
        end: "550%",
        scrub: true,
        onUpdate: () => {
            renderViruses();
        }
    },
});

gsap.to(frames, {
    frame: frameCountBack - 1,
    snap: "frame",
    scale: 1,
    scrollTrigger: {
        trigger: fiveSection,
        start: "top bottom",
        end: "top 30%",
        scrub: true,
        onUpdate: () => {
            renderBack();
        },
    }
});

tl.to(canvas, {
    scale: 0.49,
    immediateRender: false,
    scrollTrigger: {
        trigger: fiveSection,
        start: "top top",
        end: "50%",
        scrub: true,
        pin: true,
        onEnter: () => {
            if(isIphone()) wrapper.style.top = "55px";
        },
        onLeaveBack: () => {

            if(isIphone()) wrapper.style.top = "0";
        },
        onLeave: () => {
            let baseContainer = document.querySelector('.scene.four');
            wrapper.style.position = 'absolute';
            if(isIphone()) wrapper.style.top = window.pageYOffset + baseContainer.getBoundingClientRect().top + baseContainer.offsetHeight*1.5 + 55 + "px";
            else wrapper.style.top = window.pageYOffset + baseContainer.getBoundingClientRect().top + baseContainer.offsetHeight*1.5 + "px";
        },
        onEnterBack: () => {
            wrapper.style.position = 'fixed';

            if(isIphone())  wrapper.style.top = "55px";
            else  wrapper.style.top = "0";
        }
    },

});

function renderOne() {
    clearCanvas(context);
    context.drawImage(images[frames.frame], 0, 0);
}

function renderBack() {
    clearCanvas(context);
    context.drawImage(imagesBack[frames.frame], 0, 0);
}

function renderViruses() {
    clearCanvas(context);
    context.drawImage(viruses[frames.frame], 0, 0);
}

function clearCanvas(element) {
    element.clearRect(0, 0, canvas.width, canvas.height);
}

window.addEventListener('resize', () => {
    setVhProp();
});

setVhProp();

function setVhProp() {
    let vh = "100vh";
    if (isTouch()) {
        const vh = `${window.outerHeight}px`;
        document.documentElement.style.setProperty('--vh', vh);
    }
}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
let vh2 = window.innerHeight * 1;
document.documentElement.style.setProperty('--vh2', `${vh2}px`);

let numberInput = document.getElementById('number');
let costInput = document.getElementById('floorInput');
let compare = document.getElementById('cost');
let compareTo = document.getElementById('cost-compare');

numberInput.addEventListener('change', calcSurfaceCost);

function calcSurfaceCost( event ){
    let compareArray = {
        1:{cost: 22, value: "guma do żucia - 22 gr"},
        2:{cost:25, value: "tabletka paracetamolu - 25 gr"},
        3:{cost:50,value: "kajzerka 50 gr"},
        4:{cost:70,value: "chusteczki higieniczne - 70 gr"},
        5:{cost:80,value: "papieros - 80 gr"},
        6:{cost:90,value: "kapsułka do zmywarki 90 gr"},
        7:{cost:120,value: "wafel w czekoladzie - 1,20 zł"},
        8:{cost: 170,value: "woda butelkowa 1,70 zł"},
    };

    let cost = numberInput.value/15 * 707.25;
    costInput.value = cost.toFixed(2).toString() + ' zł';
    compare.classList.add('visible');
    let fixedCost = (cost/10950).toFixed(2);

    compare.querySelector('.years').innerHTML = fixedCost;

    for (let compareValue in compareArray) {
        let current = compareArray[compareValue];
        if (current.cost/100 > fixedCost) {
            compareTo.classList.add('visible');
            compareTo.querySelector('span').innerHTML = current.value;
            break;
        }
        else compareTo.classList.remove('visible');
    }
}


const toggleButtons = document.querySelectorAll('.toggle-button');

toggleButtons.forEach  (function(el) {
    el.addEventListener('click', function(e){
    e.preventDefault();
    document.body.classList.toggle(el.dataset.classToggle);
  });
});

let globalControls = {
    controlsText: ['<svg width="28" height="53" viewBox="0 0 28 53"><use xlink:href="#arrowleft"></use></svg>','<svg width="28" height="53" viewBox="0 0 28 53"><use xlink:href="#arrowright"></use></svg>'],
    speed: 1000,
    controls: true,
    autoplayButtonOutput:false,
    autoplayHoverPause: true,
    controlsPosition: 'bottom',
    nav:false,
};

tns({
    container: '.testimonials',
    autoplay: true,
    items:1,
    navPosition: 'bottom',
    ...globalControls,
});

tns({
    ...globalControls,
    container: '.target',
    loop: false,
    navPosition:'bottom',
    items:1,

    responsive: {
      860: {
        items: 2
      },
    }
})

ScrollTrigger.matchMedia({
    "(min-width:760px)": () => {
        ScrollTrigger.create({
            animation: gsap.fromTo('.icon-box',{opacity:0},{duration:1.5,opacity:1,stagger:0.5}) ,
            trigger: '.seven',
            start: 'top top',
            end: 'bottom 200px',
            scrub: 1,
            pin: true,
        });
    },
    "(max-width:760px)": () => {
        ScrollTrigger.create({
            animation: gsap.fromTo('.icon-box',{opacity:0},{duration:1.5,opacity:1,stagger:0.5}) ,
            trigger: '.seven',
            start: 'top 80%',
            end: 'bottom 50%',
            scrub: 1,
        });
    }
});


ScrollTrigger.create({
  trigger: '.one',
  endTrigger:'.two',
  onEnter: () => onEnter(),
  onLeave: () => onLeave(),
  onEnterBack: () => onEnter(),
  onLeaveBack: () => onLeave(),
});

ScrollTrigger.create({
  trigger: '.five',
  start:"top top",
  endTrigger:'.seven',
  onEnter: () => onEnter(),
  onLeave: () => onLeave(),
  onEnterBack: () => onEnter(),
  onLeaveBack: () => onLeave(),
});


function onEnter(){
    document.body.classList.add('white-logo');
    cursorWrapper.style.visibility = 'visible';
}

function onLeave(){
    document.body.classList.remove('white-logo');
    cursorWrapper.style.visibility = 'hidden';
}

const openBtn = document.querySelectorAll('.open-modal');
const closeBtn = document.querySelectorAll('.close_btn');

openBtn.forEach( function(button, index) {
    button.addEventListener('click', (e) => {
        e.preventDefault();
        document.body.classList.add('modal-open');

        gsap.to('.loading', 1, {
            height: '100vh',
            ease: Expo.easeInOut
        })
        gsap.to('.loading', 1, {
            height: '0',
            delay: 1.2,
            ease: Power2.easeInOut
        })
        gsap.to(button.dataset.modal, 1.5, {
            delay: 0.5,
            visibility: 'visible',
            opacity: 1,
            height: '100vh',
            ease: Power2.easeInOut
        })
    });
});

closeBtn.forEach( function(button, index) {
    button.addEventListener('click', (e) => {
        e.preventDefault();

        gsap.to('.loading', 1, {
            height: '100vh',
            ease: Expo.easeInOut
        })

        gsap.to('.loading', 1, {
            height: '0',
            delay: 1.2,
            ease: Power2.easeInOut
        })

        gsap.to(button.dataset.modal, 1.5, {
            delay: 0.5,
            visibility: 'hidden',
            opacity: 0,
            height: '0',
            ease: Power2.easeInOut,
            onEnd: () => {
                document.body.classList.remove('modal-open')
            }
        });
    });
});

document.querySelectorAll('input').forEach((input) => {
    input.focus({preventScroll: true});
});


const links = document.querySelectorAll(".main-navigation a");

links.forEach((link) => {
  link.addEventListener("click", clickHandler);
});

function clickHandler(e) {
  e.preventDefault();
  const href = this.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop;
  document.body.classList.toggle('toggled');

  scroll({
    top: offsetTop,
    behavior: "smooth"
  });
}